@import 'styles/layers.scss';

@layer uikit {
  .menu {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      background: inherit;
    }
  }
  
  .toggle {
    padding: 0;
    height: unset;
  }
}